import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Container, Grid, InputAdornment, Link, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import FireWorks from 'src/components/FireWorks';
import FormErrors from 'src/components/form/FormErrors';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';
import config from 'src/config/index';
import companiesExpectedErrors from 'src/graphql/companies/expectedErrors';
import usersExpectedErrors from 'src/graphql/users/expectedErrors';
import { REGISTER, SEND_EMAIL_VERIFICATION } from 'src/graphql/users/mutations';
import { VERIFIED_USER_EMITTED } from 'src/graphql/users/subscriptions';
import useAntiSpammingAction from 'src/hooks/useAntiSpammingAction';
import useAuth from 'src/hooks/useAuth';
import useFormatDateTime from 'src/hooks/useFormatDateTime';
import useLocales from 'src/hooks/useLocales';
import useRecaptcha from 'src/hooks/useRecaptcha';
import { PATH_AUTH } from 'src/routes/paths';
import { useSafeMutation, useSafeSubscription } from 'src/services/apollo-client/wrappers';
import cssStyles from 'src/utils/cssStyles';
import { NUMBER_FORMATS, fEuro } from 'src/utils/formatNumber';
import * as Yup from 'yup';

import FullScreenImageContainer from './FullScreenImageContainer';

const CreateOrVerifyAccount = () => {
  const theme = useTheme();
  const { company: myCompany, isAuthenticated, logout, onLoginSuccess, user } = useAuth();
  const { fDateToUtc } = useFormatDateTime();
  const { executeRecaptcha } = useRecaptcha();
  const location = useLocation();
  const navigate = useNavigate();
  const { translate } = useLocales();

  if (isAuthenticated && user.emailVerified) navigate(PATH_AUTH.onboarding, { replace: true });

  const { data } = location.state || {};

  useSafeSubscription(VERIFIED_USER_EMITTED);

  const [sendEmailVerification] = useSafeMutation(SEND_EMAIL_VERIFICATION);

  const [safeSendEmailVerification, { canPerformAction }] = useAntiSpammingAction(
    'sendEmailVerification',
    sendEmailVerification
  );

  const [register, { error }] = useSafeMutation(REGISTER, {
    onCompleted: ({ register: result }) => {
      onLoginSuccess(result.token.token);
    },
    refetchQueries: ['listCompanies'],
  });

  const ValidateRegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate('commons.invalidEmail'))
      .required(translate(`commons.requiredField`)),
  });

  const defaultValues = {
    email: user?.email || data?.user?.email,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(ValidateRegisterSchema),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = methods;

  const onSubmit = async ({ email }) => {
    try {
      if (isAuthenticated) {
        await safeSendEmailVerification();
      } else {
        await register({
          variables: {
            company: {
              creationDate: fDateToUtc(data.company.creationDate),
              customCompanyName: data.company.customCompanyName,
              financing: data.financing,
              linkedinUrl: data.user.linkedinUrl,
              offer: data.offer,
              selectedOffers: data.selectedOffers,
              siren: data.company.siren,
              tools: data.tools,
              websiteUrl: data.company.websiteUrl,
            },
            recaptchaToken: await executeRecaptcha('registerAction'),
            user: {
              email,
              firstname: data.user.firstname,
              lastname: data.user.lastname,
              phone: data.user.phone,
              position: data.user.position,
            },
          },
        });
      }
    } catch {
      // Nothing to do
    }
  };

  const financingSimulationPropertiesDependingOnAuth = () => {
    if (!isAuthenticated) {
      return {
        amount: data.financing.amount,
        duration: data.financing.duration,
        firstname: data.user.firstname,
      };
    }
    return {
      amount: myCompany.metadata.financingSimulation.amount,
      duration: myCompany.metadata.financingSimulation.duration,
      firstname: user.firstname,
    };
  };

  const helperContentDependingOnAuth = () => {
    if (!isAuthenticated) {
      return {
        endText: (
          <>
            {translate(
              'sections.onboardingPendingRegistrationAndEmailVerifying.notAuthenticated.endText1'
            )}
            {/* cspell-checker: disable-next-line */}
            <Link href={`${config.landingUrl}/conditions-generales-dutilisation`} target="_blank">
              {translate('sections.authRegisterCompanyFormAgreementCGUText')}
            </Link>
            {translate(
              'sections.onboardingPendingRegistrationAndEmailVerifying.notAuthenticated.endText2'
            )}
            {/* cspell-checker: disable-next-line */}
            <Link href={`${config.landingUrl}/politique-de-confidentialites`} target="_blank">
              {translate('sections.authRegisterCompanyFormAgreementPrivacyPolicyText')}
            </Link>
            .
          </>
        ),
        title: translate(
          'sections.onboardingPendingRegistrationAndEmailVerifying.notAuthenticated.title'
        ),
      };
    }
    return {
      endText: translate(
        'sections.onboardingPendingRegistrationAndEmailVerifying.authenticated.endText'
      ),
      title: translate(
        'sections.onboardingPendingRegistrationAndEmailVerifying.authenticated.title'
      ),
    };
  };

  const textButtonDependingOnAuth = isAuthenticated
    ? translate('sections.onboardingSendEmailAction')
    : translate('sections.onboardingRegisterAction');

  return (
    <FullScreenImageContainer friezeText="Félicitations">
      <FireWorks />
      <Stack direction="column" justifyContent="center" mt={3} spacing={4}>
        <Container maxWidth="md" spacing={3}>
          <Stack justifyContent="center" mb={2} spacing={2}>
            {!isAuthenticated ? (
              <>
                <Typography align="center" color="textPrimary" sx={{ mb: 1 }} variant="h1">
                  {translate('sections.onboardingPendingRegistrationOrEmailVerifyingHeaderText1', {
                    firstname: financingSimulationPropertiesDependingOnAuth().firstname,
                  })}
                  <Typography variant="inherit">
                    {translate('sections.onboardingPendingRegistrationOrEmailVerifyingHeaderText2')}
                  </Typography>
                </Typography>
                <Typography align="center" variant="h3">
                  {translate(
                    'sections.onboardingPendingRegistrationOrEmailVerifyingSubHeaderAmount'
                  )}
                  <Typography color="primary.main" component="span" variant="inherit">
                    {fEuro({
                      format: NUMBER_FORMATS.INTEGER,
                      number: financingSimulationPropertiesDependingOnAuth().amount,
                      withoutTypography: true,
                    })}
                  </Typography>
                  <Typography variant="inherit">
                    {translate(
                      'sections.onboardingPendingRegistrationOrEmailVerifyingSubHeaderDuration'
                    )}
                    <Typography color="primary.main" component="span" variant="inherit">
                      {financingSimulationPropertiesDependingOnAuth().duration} mois.
                    </Typography>
                  </Typography>
                </Typography>
              </>
            ) : (
              <>
                <Typography align="center" color="textPrimary" sx={{ mb: 1 }} variant="h1">
                  {translate('sections.onboardingPendingRegistrationAuthenticatedHeaderTitle', {
                    firstname: user.firstname,
                  })}
                </Typography>
                <Typography align="center" variant="h3">
                  {translate('sections.onboardingPendingRegistrationVerifyInboxTitle')}
                  <Typography color="primary.main" variant="h3">
                    {user.email}
                  </Typography>
                  {translate('sections.onboardingPendingRegistrationValidateAccountHelperText')}
                </Typography>
              </>
            )}
          </Stack>

          <Stack justifyContent="center" spacing={2}>
            <Typography align="center" color="text.secondary" variant="body2">
              {helperContentDependingOnAuth().title}
            </Typography>
            <FormErrors
              error={error}
              expectedErrors={[
                usersExpectedErrors.EMAIL_DOMAIN_ALREADY_EXISTS,
                usersExpectedErrors.NON_BUSINESS_DOMAIN,
                usersExpectedErrors.USER_ALREADY_EXISTS,
                companiesExpectedErrors.SIREN_ALREADY_EXISTS,
              ]}
            />
            {!isAuthenticated ? (
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <RHFTextField
                  defaultValue={user?.email || data?.user?.email}
                  disabled={isAuthenticated}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LoadingButton
                          color="primary"
                          disabled={!canPerformAction}
                          endIcon={!isAuthenticated && <Iconify icon="mdi:arrow-right" />}
                          loading={isSubmitting}
                          type="submit"
                          variant="gradient"
                        >
                          {textButtonDependingOnAuth}
                        </LoadingButton>
                      </InputAdornment>
                    ),
                    sx: {
                      ...cssStyles().bgBlur({
                        backdropFilter: 6,
                        color: theme.palette.background.default,
                        opacity: 0.3,
                      }),
                    },
                  }}
                  name="email"
                  required
                  variant="outlined"
                />
              </FormProvider>
            ) : (
              <LoadingButton
                color="primary"
                disabled={!canPerformAction}
                endIcon={<Iconify icon="mdi:arrow-right" />}
                loading={isSubmitting}
                onClick={onSubmit}
                sx={{
                  alignSelf: 'center',
                  maxWidth: 500,
                }}
                type="submit"
                variant="gradient"
              >
                {textButtonDependingOnAuth}
              </LoadingButton>
            )}

            <Typography align="center" color="text.secondary" variant="caption">
              {helperContentDependingOnAuth().endText}
            </Typography>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link component="button" onClick={logout}>
              Déconnexion
            </Link>
          </Stack>
        </Container>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item md={4} xs={12}>
            <Stack alignItems="center" direction="row" justifyContent="center" spacing={2}>
              <Typography align="center" color="textPrimary" variant="body1">
                {translate('sections.FinancingIn48h')}
              </Typography>
              <Iconify
                icon="eva:checkmark-circle-2-outline"
                sx={{ color: 'primary.main', ml: 1, mr: 1 }}
              />
            </Stack>
          </Grid>
          <Grid item md={4} xs={12}>
            <Stack alignItems="center" direction="row" justifyContent="center" spacing={2}>
              <Typography align="center" color="textPrimary" variant="body1">
                {translate('sections.noHiddenFees')}
              </Typography>
              <Iconify
                icon="eva:checkmark-circle-2-outline"
                sx={{ color: 'primary.main', ml: 1, mr: 1 }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </FullScreenImageContainer>
  );
};

export default CreateOrVerifyAccount;
