import confetti from 'canvas-confetti';
import { useEffect } from 'react';

const FireWorks = () => {
  const duration = 3 * 1000;
  const animationEnd = Date.now() + duration;
  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  useEffect(() => {
    const defaults = { spread: 360, startVelocity: 30, ticks: 60, zIndex: 0 };

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        clearInterval(interval);
        return;
      }

      const particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti({
        ...defaults,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        particleCount,
      });
      confetti({
        ...defaults,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        particleCount,
      });
    }, 250);
  }, [animationEnd, duration]);

  return null;
};

export default FireWorks;
