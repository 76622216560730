import i18n from 'src/locales/i18n';

const CURRENCY_CODES = {
  AED: 'AED',
  AUD: 'AUD',
  BRL: 'BRL',
  CAD: 'CAD',
  CHF: 'CHF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  DZD: 'DZD',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  JPY: 'JPY',
  KRW: 'KRW',
  KWD: 'KWD',
  MXN: 'MXN',
  MYR: 'MYR',
  NOK: 'NOK',
  NZD: 'NZD',
  PEN: 'PEN',
  PHP: 'PHP',
  QAR: 'QAR',
  RUB: 'RUB',
  SAR: 'SAR',
  SEK: 'SEK',
  SGD: 'SGD',
  THB: 'THB',
  TND: 'TND',
  TRY: 'TRY',
  UAH: 'UAH',
  USD: 'USD',
  ZAR: 'ZAR',
};
const CURRENCY_OPTIONS = [
  { label: i18n.t('commons.dealsAnalysisStatus.ALL'), value: 'all' },
  { before: false, label: 'Euro', symbol: '€', value: CURRENCY_CODES.EUR },
  { before: false, label: 'Dollar Américain', symbol: '$', value: CURRENCY_CODES.USD },
  { before: false, label: 'Livre Sterling', symbol: '£', value: CURRENCY_CODES.GBP },
  { before: false, label: 'Yen Japonais', symbol: '¥', value: CURRENCY_CODES.JPY },
  { before: false, label: 'Franc Suisse', symbol: 'CHF', value: CURRENCY_CODES.CHF },
  { before: false, label: 'Dollar Canadien', symbol: 'CA$', value: CURRENCY_CODES.CAD },
  { before: false, label: 'Dollar Australien', symbol: 'AU$', value: CURRENCY_CODES.AUD },
  { before: false, label: 'Yuan Chinois', symbol: '¥', value: CURRENCY_CODES.CNY },
  { before: false, label: 'Rouble Russe', symbol: '₽', value: CURRENCY_CODES.RUB },
  { before: false, label: 'Roupie Indienne', symbol: '₹', value: CURRENCY_CODES.INR },
  { before: false, label: 'Real Brésilien', symbol: 'R$', value: CURRENCY_CODES.BRL },
  { before: false, label: 'Peso Mexicain', symbol: 'MEX$', value: CURRENCY_CODES.MXN },
  { before: false, label: 'Rand Sud-Africain', symbol: 'R', value: CURRENCY_CODES.ZAR },
  { before: false, label: 'Won Sud-Coréen', symbol: '₩', value: CURRENCY_CODES.KRW },
  { before: false, label: 'Dollar Néo-Zélandais', symbol: 'NZ$', value: CURRENCY_CODES.NZD },
  { before: false, label: 'Couronne Suédoise', symbol: 'kr', value: CURRENCY_CODES.SEK },
  { before: false, label: 'Couronne Norvégienne', symbol: 'kr', value: CURRENCY_CODES.NOK },
  { before: false, label: 'Lire Turque', symbol: '₺', value: CURRENCY_CODES.TRY },
  { before: false, label: 'Riyal Saoudien', symbol: 'ر.س', value: CURRENCY_CODES.SAR },
  { before: false, label: 'Dirham Émirati', symbol: 'د.إ', value: CURRENCY_CODES.AED },
  { before: false, label: 'Dollar de Singapour', symbol: 'S$', value: CURRENCY_CODES.SGD },
  { before: false, label: 'Baht Thaïlandais', symbol: '฿', value: CURRENCY_CODES.THB },
  { before: false, label: 'Ringgit Malaisien', symbol: 'RM', value: CURRENCY_CODES.MYR },
  { before: false, label: 'Peso Philippin', symbol: '₱', value: CURRENCY_CODES.PHP },
  { before: false, label: 'Dollar de Hong Kong', symbol: 'HK$', value: CURRENCY_CODES.HKD },
  { before: false, label: 'Hryvnia Ukrainien', symbol: '₴', value: CURRENCY_CODES.UAH },
  { before: false, label: 'Roupie Indonésienne', symbol: 'Rp', value: CURRENCY_CODES.IDR },
  // cspell-checker: disable-next-line
  { before: false, label: 'Nuevo Sol Péruvien', symbol: 'S/', value: CURRENCY_CODES.PEN },
  { before: false, label: 'Peso Colombien', symbol: 'COP$', value: CURRENCY_CODES.COP },
  { before: false, label: 'Dinar Algérien', symbol: 'د.ج', value: CURRENCY_CODES.DZD },
  { before: false, label: 'Dinar Tunisien', symbol: 'د.ت', value: CURRENCY_CODES.TND },
  { before: false, label: 'Peso Chilien', symbol: 'CLP$', value: CURRENCY_CODES.CLP },
  { before: false, label: 'Dinar Koweïtien', symbol: 'د.ك', value: CURRENCY_CODES.KWD },
  { before: false, label: 'Riyal Qatari', symbol: 'ر.ق', value: CURRENCY_CODES.QAR },
  { before: false, label: 'Shekel Israélien', symbol: '₪', value: CURRENCY_CODES.ILS },
];

const SEPA_COUNTRY_CODES = [
  'AT',
  'BE',
  'CY',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PT',
  'SK',
  'SI',
  'ES',
  'BG',
  'CZ',
  'DK',
  'HR',
  'HU',
  'IS',
  'LI',
  'NO',
  'PL',
  'RO',
  'SE',
  'CH',
  'GB',
  'SM',
  'MC',
];

function getCurrencyOptionByCode(currencyCode) {
  return (
    CURRENCY_OPTIONS.find((option) => option.value === currencyCode) ||
    getCurrencyOptionByCode(CURRENCY_CODES.EUR)
  );
}

function isSEPAIban({ currency, iban }) {
  return SEPA_COUNTRY_CODES.includes(iban.slice(0, 2)) && currency === CURRENCY_CODES.EUR;
}

export { CURRENCY_CODES, CURRENCY_OPTIONS, getCurrencyOptionByCode, isSEPAIban };
