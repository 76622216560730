import { AppBar, Box, Link, Toolbar, Typography, styled } from '@mui/material';
import React from 'react';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import cssStyles from 'src/utils/cssStyles';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderTopColor: theme.palette.grey[300],
  borderTopStyle: 'dashed',
  borderTopWidth: 1,
  position: 'relative',
  ...cssStyles(theme).bgBlur(),
}));

const BottomGradientLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gradients.main,
  backgroundImage: theme.palette.gradients.main,
  bottom: 0,
  height: 3,
  left: 0,
  position: 'absolute',
  width: '100%',
  zIndex: 1,
}));

const LoggedAsAppBar = () => {
  const { translate } = useLocales();
  const { isLoggedAsUser, logout, user } = useAuth();

  if (!isLoggedAsUser) return null;

  return (
    <StyledAppBar elevation={1}>
      <Toolbar
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          minHeight: `1px !important`,
          position: 'relative',
          py: 1,
        }}
      >
        <Typography color="text.primary" variant="caption">
          {translate('layouts.dashboardHeaderLoggedAsText', {
            displayName: user.displayName,
          })}
        </Typography>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link component="button" onClick={logout} size="small" variant="outlined">
          {translate('layouts.dashboardHeaderLoggedAsLogoutAction')}
        </Link>
        <BottomGradientLine />
      </Toolbar>
    </StyledAppBar>
  );
};

export default LoggedAsAppBar;
