import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import MUIStepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import * as React from 'react';

import Image from './Image';

const CustomStepperConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[300],
    borderRadius: 1,
    borderTopWidth: 3,
  },
}));

const InsensitiveStepperConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    top: 10,
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[300],
    borderRadius: 1,
    borderTopWidth: 3,
  },
}));

const CustomStepperStepIconRoot = styled('div')(({ ownerState, theme }) => ({
  alignItems: 'center',
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[400],
  display: 'flex',
  height: 22,
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
  '& .CustomStepperStepIcon-circle': {
    backgroundColor: 'currentColor',
    borderRadius: '50%',
    height: 8,
    width: 8,
  },
  '& .CustomStepperStepIcon-completedIcon': {
    color: theme.palette.primary.main,
    fontSize: 18,
    zIndex: 1,
  },
}));

const CustomStepperStepIcon = (props) => {
  const { active, className, completed } = props;

  return (
    <CustomStepperStepIconRoot className={className} ownerState={{ active }}>
      {completed ? (
        <Image src="/icons/glass/cube.png" sx={{ cursor: 'pointer', width: 20 }} />
      ) : (
        <Image
          src={active ? '/icons/glass/cube.png' : '/icons/glass/cube_disabled.png'}
          sx={{ cursor: active ? 'pointer' : 'cursor', width: 20 }}
        />
      )}
    </CustomStepperStepIconRoot>
  );
};

const Stepper = ({ activeStep, steps }) => (
  <Stack spacing={4} sx={{ width: '100%' }}>
    <MUIStepper activeStep={activeStep} alternativeLabel connector={<CustomStepperConnector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={CustomStepperStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </MUIStepper>
  </Stack>
);

export { CustomStepperStepIcon, InsensitiveStepperConnector, CustomStepperConnector };
export default Stepper;
